<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <a
                    class="btn btn-primary"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                    >Filter</a
                  >
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'CrudServiceCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                    @keyup="getData"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="collapse show" id="collapseExample">
                <div class="card card-body">
                  <p class="font-weight-bold">Filter</p>
                  <filter-table @change="getData" @reset="resetFilter">
                    <template v-slot:selects>
                      <select-input
                        :value.sync="filters.menu_id"
                        label="Menu"
                        :options="data.menus"
                        classes
                      ></select-input>
                      <select-input
                        :value.sync="filters.menu_child_id"
                        label="Child"
                        :options="data.menuChilds"
                        classes
                      ></select-input>
                      <select-input
                        :value.sync="filters.role_id"
                        label="Role"
                        :options="data.roles"
                        classes
                      ></select-input>
                    </template>
                  </filter-table>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="crudServices.data"
                item-key="id"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      <router-link
                        :to="{
                          name: 'CrudServiceEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteData(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import {
  MenuRepository,
  CrudServiceRepository,
  MenuChildRepository,
  RoleRepository,
} from "../../repositories/RepositoryFactory";

export default {
  name: "CrudService",
  data() {
    return {
      name: "CRUD Service",
      filters: {
        menu_id: "",
        menu_child_id: "",
        role_id: "",
      },
      search: "",
      data: {
        menus: [],
        menuChilds: [],
        roles: [],
      },
      crudServices: [],
    };
  },
  mounted() {
    this.getMenus();
    this.getMenuChilds();
    this.getRoles();
    this.getData();
  },
  methods: {
    getData() {
      CrudServiceRepository.get(this.filters).then(({ data }) => {
        this.crudServices = data.data;
      });
    },
    resetFilter() {
      Object.keys(this.filters).forEach((e) => {
        this.filters[e] = null;
      });
    },
    deleteData(id) {
      CrudServiceRepository.delete(id);
    },
    getMenus() {
      MenuRepository.get().then(({ data }) => {
        this.data.menus = data.data.map((e) => {
          return {
            value: e.id,
            text: e.name,
          };
        });
      });
    },
    getMenuChilds() {
      MenuChildRepository.get().then(({ data }) => {
        this.data.menuChilds = data.data.map((e) => {
          return {
            value: e.id,
            text: e.name,
          };
        });
      });
    },
    getRoles() {
      RoleRepository.getForSelect().then(({ data }) => {
        this.data.roles = data.data;
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Role",
          value: "role",
        },
        {
          text: "Service",
          value: "service",
        },
      ];
    },
  },
};
</script>
